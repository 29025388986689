<template>
	<div style="margin: 0">
		<div id="index">
			<!-- 头部 -->
			<div class="header">
				<div class="box">
					<img class="logo" src="../assets/Images/logo.png" alt="">
					<div class="text">
						<span>诚招项目合伙人</span>
						<span class="hotline">合作热线：</span>
						<span class="phone">400-035-6665</span>
					</div>
				</div>
			</div>
			<!-- banner -->
			<div class="banner">
				<img src="../assets/Images/banner.png" alt="">
			</div>
			<!-- 右边官方公众号与项目合作 -->
			<div class="officialAccount">
				<div class="box">
					<div class="gzh">
						<img src="../assets/Images/wx.png" alt="" />
						<p>公众号</p>
						<div class="erweima">
							<img src="../assets/Images/aisudu_code.jpg" alt="" />
							<p class="text">艾速度</p>
						</div>
					</div>
					<a href="#dimension-table-info">
						<div class="projectCooperation">
							<img src="../assets/Images/hz.png" alt="" />
							<p>项目合作</p>
						</div>
					</a>
				</div>
			</div>
			<!-- 评测学系统移动终端新一代艾速度智能学伴 -->
			<div class="mobile-terminal">
				<h2 class="title">评测学系统移动终端<br />新一代艾速度智能学伴</h2>
				<div class="content">
					<img class="mobile" src="../assets/Images/mobile.png" alt="">
					<div class="content-text-box wrap">
						<div class="top">
							<div class="box">
								<div class="tit">硬件优势</div>
								<p class="text">
									10.1英寸高清屏幕<br /> 双引擎八核处理器
									<br /> 7100mAh大容量电池
									<br /> 1920 x 1200高分辨率
								</p>
							</div>
							<div class="box">
								<div class="tit">绿色安全</div>
								<p class="text">
									预装评测学自适应学习系统、<br /> 艾速度定制浏览器
									<br />
									<span class="link">
                                    支持经销商自主设置可用网址<br />
                                    支持经销商自定义安装APP
                                </span>
								</p>
							</div>
						</div>
						<div class="bottom">
							<div class="box">
								<div class="tit">科目齐全</div>
								<p class="text">
									语文、数学、英语、物理、<br /> 化学、生物、科学（浙教版）
									<br /> 其他科目持续更新
									<br />
								</p>
							</div>
							<div class="box">
								<div class="tit">资源丰富</div>
								<p class="text">
									覆盖不同版本教材<br /> 汇集海量学习资源
									<br /> 按照学生情况个性化分发
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 人工智能个性化教学实现高质量自主学习 -->
			<div class="autonomous-learning">
				<h2 class="title">人工智能个性化教学<br />实现高质量自主学习</h2>
				<div class="content">
					<ul class="learning-ul">
						<li>
							<div class="img-box">
								<img src="../assets/Images/hexagon.png" alt="">
								<span class="text">智能诊断</span>
							</div>
							<div class="text-box">
								<p class="one">智能诊断知识结构，找到薄弱环节</p>
								<p class="two">——告诉学生需要补哪里</p>
							</div>
						</li>
						<li>
							<div class="img-box">
								<img src="../assets/Images/hexagon.png" alt="">
								<span class="text">智能规划</span>
							</div>
							<div class="text-box">
								<p class="one">根据薄弱点，智能规划个性化学习路径</p>
								<p class="two">——告诉学生应从哪儿开始学</p>
							</div>
						</li>
						<li>
							<div class="img-box">
								<img src="../assets/Images/hexagon.png" alt="">
								<span class="text">智能推送</span>
							</div>
							<div class="text-box">
								<p class="one">根据薄弱点及学习能力，智能推送学习资料</p>
								<p class="two">——告诉学生适合学什么</p>
							</div>
						</li>
						<li>
							<div class="img-box">
								<img src="../assets/Images/hexagon.png" alt="">
								<span class="text">智能评估</span>
							</div>
							<div class="text-box">
								<p class="one">智能评估学习效果，及时调整学习内容、学习路径，因材施教</p>
								<p class="two">——告诉学生接下来适合学什么</p>
							</div>
						</li>
					</ul>
					<!-- 适用自主学习场景 -->
					<div class="scene">
						<p class="tit">适用自主学习场景</p>
						<ul class="scene-ul">
							<li>
								<div class="show">
									<img src="../assets/Images/scene-1.png" alt="">
									<p class="text">自主补差补弱</p>
								</div>
								<div class="hover-desc">精准定位薄弱点，孩子可<span>跟随学习机引导</span>，自主完成薄弱知识点的学习</div>
							</li>
							<li>
								<div class="show">
									<img src="../assets/Images/scene-2.png" alt="">
									<p class="text">智能自学</p>
								</div>
								<div class="hover-desc">提供<span>问题诊断、推送资料、学习评估、调整路径</span>的闭环学习，孩子可跟随引导完成自主学习</div>
							</li>
							<li>
								<div class="show">
									<img src="../assets/Images/scene-3.png" alt="">
									<p class="text">课程预习</p>
								</div>
								<div class="hover-desc">教材同步，针对知识点建立<span>边学边练</span>的学习程序，孩子可针对相关章节知识点进行预习</div>
							</li>
							<li>
								<div class="show">
									<img src="../assets/Images/scene-4.png" alt="">
									<p class="text">课后复习</p>
								</div>
								<div class="hover-desc">教材同步，针对知识点建立<span>边学边练</span>的学习程序，孩子可针对相关章节进行复习</div>
							</li>
						</ul>
						<ul class="scene-ul scene-ul-two">
							<li>
								<div class="show">
									<img src="../assets/Images/scene-5.png" alt="">
									<p class="text">薄弱点诊断</p>
								</div>
								<div class="hover-desc">智能诊断，精准定位薄弱点；追根溯源，可找到<span>跨年级、跨学科</span>的知识问题</div>
							</li>
							<li>
								<div class="show">
									<img src="../assets/Images/scene-6.png" alt="">
									<p class="text">查漏补缺</p>
								</div>
								<div class="hover-desc">针对<span>薄弱点、学习能力</span>推送学习内容，个性化查漏补缺</div>
							</li>
							<li>
								<div class="show">
									<img src="../assets/Images/scene-7.png" alt="">
									<p class="text">夯实基础</p>
								</div>
								<div class="hover-desc">从问题根源出发，学习<span>问题层层修复</span>，帮助孩子夯实基础</div>
							</li>
							<li>
								<div class="show">
									<img src="../assets/Images/scene-8.png" alt="">
									<p class="text">错题清理</p>
								</div>
								<div class="hover-desc">自动收集错题、智能推送错题重做，并提供解题线索，引导孩子<span>独自正确答题</span></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- 搭建线下6S体验服务中心与家长合力培养优秀人才 -->
			<div class="service-centre">
				<h2 class="title">搭建线下6S体验服务中心<br />与家长合力培养优秀人才</h2>
				<img class="service-centre-img" src="../assets/Images/service-centre-img.png" alt="">
				<p class="centre-text">艾速度智能学伴6S体验服务中心</p>
				<div class="content">
					<div class="one box">
						<div class="text-box ml">
							<p class="tit">销售（Sale）</p>
							<p class="text">智能软硬件销售</p>
						</div>
						<div class="text-box">
							<p class="tit">零配件（Sparepart）</p>
							<p class="text">耳机、键盘等零配件销售</p>
						</div>
					</div>
					<div class="two box">
						<div class="text-box">
							<p class="tit">售后服务（Service）</p>
							<p class="text">硬件维修服务</p>
							<p class="text">软件使用指导</p>
						</div>
						<div class="text-box">
							<p class="tit">信息反馈（Survey）</p>
							<p class="text">产品使用情况沟通</p>
						</div>
					</div>
					<div class="three box">
						<div class="text-box">
							<p class="tit">学习计划（Study Plan）</p>
							<p class="text">为高质量自主学习提供专业引导</p>
						</div>
						<div class="text-box">
							<p class="tit">视野（Sights）</p>
							<p class="text">引导学生重塑正确世界观、</p>
							<p class="text">价值观、人生观</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 多种高需求盈利项目整合资源全方位创收 -->
			<div class="profit-project">
				<h2 class="title">多种高需求盈利项目<br />整合资源全方位创收</h2>
				<div class="content">
					<div class="box">
						<div class="item">
							<img src="../assets/Images/profit-project-img-1.png" alt="">
							<div class="tit">艾速度智能学伴</div>
							<p class="text">家庭化学习场景中，提供自主学习的重要媒介</p>
						</div>
						<div class="item margin-left">
							<img src="../assets/Images/profit-project-img-2.png" alt="">
							<div class="tit">中小学在线课程</div>
							<p class="text">小初高多学科课程，内容健康，遵循教育规律</p>
						</div>
					</div>
					<div class="box two-box">
						<div class="item">
							<img src="../assets/Images/profit-project-img-3.png" alt="">
							<div class="tit">远程督导服务</div>
							<p class="text">心理健康咨询、厌学情绪干预、家庭教育指导等督导服务</p>
						</div>
						<div class="item margin-left">
							<img src="../assets/Images/profit-project-img-4.png" alt="">
							<div class="tit">学涯规划</div>
							<p class="text">学业生涯规划，帮助学生发掘个人兴趣，找到理想专业目标</p>
						</div>
						<div class="item margin-left">
							<img src="../assets/Images/profit-project-img-5.png" alt="">
							<div class="tit">零配件销售</div>
							<p class="text">耳机、键盘、保护皮套、适配器、数据线等易损原装配件</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 多维度营销支持项目经营更顺手 -->
			<div class="dimension">
				<h2 class="title">多维度营销支持<br />项目经营更顺手</h2>
				<div class="content">
					<div class="new-container wrap">
						<ul class="new-container-ul">
							<li>
								<img src="../assets/Images/ic-1.png" alt="">
								<p>线上裂变获客</p>
								<div class="item-desc">人人拼团、砍价活动、转
									介绍系统、整点秒杀等多
									种裂变分销玩法支持</div>
							</li>
							<li>
								<img src="../assets/Images/ic-2.png" alt="">
								<p>营销课程培训</p>
								<div class="item-desc">定期开展微信营销、社
									交裂变、家长沙龙、短
									视频营销等多种线上、
									线下培训</div>
							</li>
							<li>
								<img src="../assets/Images/ic-3.png" alt="">
								<p>专业能力培训</p>
								<div class="item-desc">学涯规划、心理咨询、
									家庭教育等多种专业培
									训课程</div>
							</li>
							<li>
								<img src="../assets/Images/ic-4.png" alt="">
								<p>系统操作培训</p>
								<div class="item-desc">提供学习机操作、学习
									系统操作、督导系统操
									作等培训</div>
							</li>
						</ul>
						<ul class="new-container-ul">
							<li>
								<img src="../assets/Images/ic-5.png" alt="">
								<p>宣传海报软文</p>
								<div class="item-desc">朋友圈产品海报、产品
									软文、宣传视频、折页
									展架、印刷宣传画册等
									资料，一应提供</div>
							</li>
							<li>
								<img src="../assets/Images/ic-6.png" alt="">
								<p>营销活动方案</p>
								<div class="item-desc">重点节日营销活动方
									案、热点海报、促销活
									动等各类营销支持</div>
							</li>
							<li>
								<img src="../assets/Images/ic-7.png" alt="">
								<p>督导资源共享</p>
								<div class="item-desc">可为没有督导服务能力
									的门店，链接其他具有
									督导服务能力的门店，
									实现资源共享</div>
							</li>
							<li>
								<img src="../assets/Images/ic-8.png" alt="">
								<p>装修开业指导</p>
								<div class="item-desc">艾速度智能学伴门店装
									修设计图、店面软装广
									告海报等相关需要提供</div>
							</li>
						</ul>
					</div>

					<div id="dimension-table-info" class="two-box">
						<div class="two-box-title">
							<img src="../assets/Images/title-icon1.png" alt="">
							<span class="text">填写合作信息，深入了解项目</span>
							<img src="../assets/Images/title-icon2.png" alt="">
						</div>
						<div class="from">
							<div class="tit">评测学官方合作信息登记表</div>
							<el-form v-loading="loading" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
								<div class="lable">
									<img class="i" src="../assets/Images/i.png" />姓名
								</div>
								<el-form-item prop="name">
									<el-input prefix-icon="ssss" v-model="ruleForm.name"></el-input>
									<img class="icon" src="../assets/Images/user.png" alt="" />
								</el-form-item>

								<div class="lable">
									<img class="i" src="../assets/Images/i.png" />2.手机
								</div>
								<el-form-item prop="phone">
									<el-input prefix-icon="ssss" v-model="ruleForm.phone"></el-input>
									<img class="icon" src="../assets/Images/phone.png" alt="" />
								</el-form-item>

								<el-form-item prop="city">
									<div class="lable">
										<img class="i" src="../assets/Images/i.png" />3.地址
									</div>
									<el-cascader ref="cascader" v-model="ruleForm.city" :options="cityList" @change="handleChange"></el-cascader>
								</el-form-item>

								<div class="lable">
									<img class="i" src="../assets/Images/i.png" />4.您的行业是？
								</div>
								<el-form-item prop="industry">
									<el-input v-model="ruleForm.industry"></el-input>
								</el-form-item>

								<el-form-item>
									<el-button class="submit" type="primary" @click="submitForm('ruleForm')">提交</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
			<!-- 16年自适应研发经验成就艾速度智能学伴 -->
			<div class="study-partner">
				<h2 class="title">16年自适应研发经验<br />成就艾速度智能学伴</h2>
				<div class="center">
					<div class="one-box wrap">
						<ul class="one-box-ul">
							<li>
								<div class="tit-box">
									<img class="study-partner-one-box-icon-1" src="../assets/Images/study-partner-one-box-icon-1.png" alt="">
									<span class="text">2005年</span>
									<img class="study-partner-one-box-icon-2" src="../assets/Images/study-partner-one-box-icon-2.png" alt="">
								</div>
								<div class="box">
									核心研发团队涉足自适 应教育领域，迄今积累 16年自适应技术研发经 验。
								</div>
							</li>
							<li>
								<div class="tit-box">
									<img class="study-partner-one-box-icon-1" src="../assets/Images/study-partner-one-box-icon-1.png" alt="">
									<span class="text">2015年</span>
									<img class="study-partner-one-box-icon-2" src="../assets/Images/study-partner-one-box-icon-2.png" alt="">
								</div>
								<div class="box">
									组建团队启动评测学自适应学习系统的研发，10月发布上线。
								</div>
							</li>
							<li>
								<div class="tit-box">
									<img class="study-partner-one-box-icon-1" src="../assets/Images/study-partner-one-box-icon-1.png" alt="">
									<span class="text">2020年</span>
									<img class="study-partner-one-box-icon-2" src="../assets/Images/study-partner-one-box-icon-2.png" alt="">
								</div>
								<div class="box">
									评测学用户群体已触达全国27个省（自治区、直辖市），服务数百万师生。
								</div>
							</li>
							<li>
								<div class="tit-box">
									<img class="study-partner-one-box-icon-1" src="../assets/Images/study-partner-one-box-icon-1.png" alt="">
									<span class="text">2021年</span>
									<img class="study-partner-one-box-icon-2" src="../assets/Images/study-partner-one-box-icon-2.png" alt="">
								</div>
								<div class="box">
									搭载评测学自适应学习 系统、远程督导系统的 艾速度智能学伴发布。
								</div>
							</li>
						</ul>
					</div>
					<div class="two-box">
						<p class="tit">历届全国自适应教育峰会</p>
						<div class="line">
							<div class="circular circular1">
								<p class="cir-line"></p>
								<div class="img-box">
									<img src="../assets/Images/2021.png" alt="">
								</div>
								<p class="year">2021年</p>
							</div>
							<div class="circular circular2">
								<p class="cir-line"></p>
								<div class="img-box">
									<img src="../assets/Images/study-partner-two-box-1.png" alt="">
								</div>
								<p class="year">2020年</p>
							</div>
							<div class="circular circular3">
								<p class="cir-line"></p>
								<div class="img-box">
									<img src="../assets/Images/study-partner-two-box-2.png" alt="">
								</div>
								<p class="year">2019年</p>
							</div>
							<div class="circular circular4">
								<p class="cir-line"></p>
								<div class="img-box">
									<img src="../assets/Images/study-partner-two-box-3.png" alt="">
								</div>
								<p class="year">2018年</p>

							</div>
							<div class="circular circular5">
								<p class="cir-line"></p>
								<div class="img-box">
									<img src="../assets/Images/study-partner-two-box-4.png" alt="">
								</div>
								<p class="year">2017年</p>

							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 产品信息 -->
			<div class="product-info">
				<img class="product" src="../assets/Images/product.png" alt="">
				<div class="box">
					<ul class="one-ul">
						<li>
							<p class="tit">产品名称</p>
							<p class="text">艾速度智能学伴</p>
						</li>
						<li>
							<p class="tit">型号</p>
							<p class="text">A1</p>
						</li>
						<li>
							<p class="tit">CPU</p>
							<p class="text">8核处理器</p>
						</li>
						<li>
							<p class="tit">系统</p>
							<p class="text">Android 11</p>
						</li>
						<li>
							<p class="tit">电池</p>
							<p class="text">7100mAh</p>
						</li>
					</ul>
					<ul class="two-ul">
						<li>
							<p class="tit">内存</p>
							<p class="text">4GB</p>
						</li>
						<li>
							<p class="tit">存储</p>
							<p class="text">64GB</p>
						</li>
						<li>
							<p class="tit">屏幕</p>
							<p class="text">10.1英寸IPS屏</p>
						</li>
						<li>
							<p class="tit">分辨率</p>
							<p class="text">1920 x 1200</p>
						</li>
						<li>
							<p class="tit">标准配件</p>
							<p class="text">蓝牙键盘、皮套、适配器、数据线、卡针</p>
						</li>
					</ul>
				</div>
			</div>
			<!-- 底部 -->
			<div class="footer">
				<div class="top">
					<img src="../assets/Images/footer-logo.png" alt="">
					<div class="tit-box">
						<p class="tit">艾速度智能学伴</p>
						<p class="text">自适应&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;赢未来</p>
					</div>
					<div></div>
				</div>
				<div class="center">
					<div class="left">
						<p class="phone phone1">商务热线：400-035-6665</p>
						<p class="phone phone2">客服热线：400-166-0012</p>
					</div>
					<div class="right">
						<div class="box">
							<img class="img-size" src="../assets/Images/aisudu_code.jpg" alt="">
							<p class="text">艾速度</p>
						</div>
						<div class="box box2">
							<img class="img-size" src="../assets/Images/footetr-ma2.png" alt="">
							<p class="text">评测学</p>
						</div>
					</div>
				</div>
				<div class="bottom">
					<p>Copyright © 2021 成都爱易佰网络科技有限公司 版权所有|蜀ICP备12029833号-2</p>
					<img src="../assets/Images/plc.png" alt="">
					<p>川公网安备51019002002339号</p>
				</div>
			</div>

			<!-- 广告 -->
			<div class="advertisement" ref="advertisement" v-if="isAdvertisement" style="display: none;">
				<div class="box">
					<img class="close" @click="closeAdvertisement()" src="../assets/Images/close.png" alt="">
					<p class="text">项目合作，请留下合作信息，总部工作人员稍后联系您</p>
					<div class="btn-box">
						<a href="#dimension-table-info">
							<p class="btn btn1">填写合作信息</p>
						</a>
						<p class="btn btn2" @click="closeAdvertisement()">再看看，稍后填</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "index",
        metaInfo: {
            title: "艾速度-官网  中小学智能个性化学习  智能学伴  学习机加盟",
            meta: [
                {
                    name: "keywords",
	                content: "艾速度，评测学，艾速度智能学伴，智能学伴价格，学习机加盟价格，英语培训转型，教培转型，学科转型，教育项目加盟，智能教育加盟"
                },
                {
                    name: "description",
                    content: "艾速度智能学伴，搭载评测学自适应学习系统，赋能机构转型，助推人机共教"
                },
            ],
        },
        data() {
            return {
                // 表单数据
                ruleForm: {
                    name: "",
                    phone: "",
                    city: [],
                    industry: "", //行业
                },
                // 验证规则
                rules: {
                    name: [
                        { required: true, message: "请填写此项", trigger: "blur" },
                        { max: 12, message: '最多输入12个字符，请重新输入', trigger: 'change' }
                    ],
                    phone: [
                        { required: true, message: "请填写此项", trigger: "blur" },
                        { pattern: /^1[34578]\d{9}$/, message: '该手机号码格式无法识别', trigger: "blur" }
                    ],
                    city: [
                        { required: true, message: "请填写此项", trigger: "change" },
                    ],
                    industry: [
                        { required: true, message: "请填写此项", trigger: "blur" },
                        { max: 100, message: '最多输入100个字符，请重新输入', trigger: 'change' }
                    ]
                },
                // 城市数据
                cityList: [],
                loading: true,
                isAdvertisement: false, //是否显示广告
            };
        },

        components: {},
        watch: {
            isAdvertisement(n, o) {
                if (n == true) {
                    this.$nextTick(() => {
                        this.$refs.advertisement.style.display = "block"
                    })
                }
            }
        },
        mounted() {

            this.getCityInfo();

            this.$nextTick(() => {
                let i = 0;
                let inter = setInterval(() => {
                    i++;

                    if (i >= 10) {
                        this.isAdvertisement = true;
                        clearInterval(inter);
                    }
                }, 1000)
            })

        },
        methods: {
            getCityInfo() {

                let _this = this;

                this.$axios("privilege.listAllCityAreaBaseInfo", {
                    userExtend: null
                }).then(res => {

                    for (let i = 0; i < res.length; i++) {
                        res[i].label = res[i].value = res[i].cityName;
                    }
                    _this.cityList = res;
                    _this.cityList = _this.make_child('100000');

                    _this.cityList = (_this.cityList).sort((a, b) => {
                        return a.cityID - b.cityID
                    })
                    _this.cityList.push({
                        label: '海外',
                        value: '海外',
                        children: [{
                            label: '其他',
                            value: '其他',
                            children: [{
                                label: '其他',
                                value: '其他',
                            }]
                        }]
                    })
                    _this.loading = false
                })
            },
            make_child(id) {
                let arr = []
                for (let i = 0; i < this.cityList.length; i++) {
                    if (this.cityList[i]["parentCityID"] == id) {
                        let children = [];
                        children = this.make_child(this.cityList[i]["cityID"]);
                        if (children.length > 0) {
                            this.cityList[i].children = children;
                        }
                        arr.push(this.cityList[i]);
                    }
                }
                return arr
            },
            toDxrmtj() {
                window.open("https://jinshuju.net/f/dxrmtj");
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) { //验证通过
                        let _this = this;
                        let city = '';
                        if (this.ruleForm.city.length > 0) {
                            for (let i = 0; i < this.ruleForm.city.length; i++) {
                                city += this.ruleForm.city[i] + ' / '
                            }
                        }
                        city = city.substr(0, city.lastIndexOf(' / '));
                        let data = {
                            name: _this.ruleForm.name,
                            phone: _this.ruleForm.phone,
                            address: city,
                            profession: _this.ruleForm.industry
                        }
                        this.$axios("client.saveInvestmentInfo", {
                            data
                        }).then(res => {
                            if (res && res != "null" && res.id) {
                                _this.$message({
                                    message: "提交成功",
                                    type: "success",
                                });
                            } else {
                                _this.$message({
                                    message: "提交失败，请联系管理员!",
                                    type: "error",
                                });
                            }
                        })
                        this.resetForm('ruleForm');

                    } else {
                        return false;
                    }
                });
            },
            // 地址
            handleChange(value) {

            },
            // 清空表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.ruleForm.city = [];
                this.$refs.cascader.$refs.panel.clearCheckedNodes();
                this.$refs.cascader.$refs.panel.activePath = [];
            },
            // 关闭广告
            closeAdvertisement() {
                this.isAdvertisement = false;
                let i = 0;
                let inter = setInterval(() => {
                    i++;
                    if (i >= 15) {
                        this.isAdvertisement = true;
                        clearInterval(inter);
                    }
                }, 1000)
            }
        },
    }
</script>

<style scoped>

	@import "../assets/index.css";

</style>