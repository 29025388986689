import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import ajax from './utils/request.js'
import axios from 'axios'
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);

Vue.prototype.$axios = ajax;
Vue.config.productionTip = false
console.log(process.env.NODE_ENV)

let onlineFlag = process.env.NODE_ENV != 'development';

Vue.prototype.JXPT_URL = onlineFlag ? "https://apis.pingcexue.com" : "http://192.168.8.30:8080" ;
// Vue.prototype.BASE_URL = onlineFlag ? res.data.BASE_URL : res.data.DEV_BASE_URL;
// Vue.prototype.JXPT_URL = onlineFlag ? res.data.JXPT_URL : res.data.DEV_PCX_URL;

new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')


