import axios from 'axios'
import Vue from 'vue';
// import DEV from '../../static/api.js'


// const baseUrl = () => Vue.prototype.BASE_URL;
// const devUrl = () => Vue.prototype.DEV_URL;


const service = axios.create({});
const ajax = (method, data = [], type = 'POST') => {

  const baseUrl = Vue.prototype.BASE_URL;
  const JXPTUrl = Vue.prototype.JXPT_URL;

  return new Promise((resolve, reject) => {
    let c = null;
    let m = null;
    if (method && method.indexOf(".") != -1) {
      let _arr = method.split(".");
      c = _arr[0];
      m = _arr[1];
    } else {
      alert("错误：没有指定可用的方法");
      return;
    }

    let url = JXPTUrl;

    // switch (c) {
    //   case "client":
    //     url = JXPTUrl;
    //     break;
    //
    //   default:
    //     url = baseUrl;
    //     break;
    // }

    let surl = `${url}/Demo/f/all/${c}.action`;

    let params = [];

    if (typeof data == "object" && !(data instanceof Array)) {
      for (var key in data) {
        params.push(data[key]);
      }
    } else {
      params = data;
    }


    let apiData = encodeURIComponent(JSON.stringify({ "method": m, "params": params }));
    // ajax源码对参数进行了二次encodeURI加密，但是axios又没有加密，所以需要进行一次手动的二次加密 ( 登陆接口密码里有%的用户无法登陆 )
    apiData = encodeURIComponent(apiData);
    var promise;

    promise = service.post(surl, `corsClient=${apiData}`, {
      headers: {
        "Content-Type": 'application/x-www-form-urlencoded',
        "Accept": "text/plain, */*; q=0.01",
      }
    });

    promise.then(res => {
      if (res.status === 200 && res.data.state == true) {
        resolve(res.data.result)
      } else {
        reject(res.data)
      }
    }).catch(err => {
      console.log("异常", err);
      reject(err);
    })
  })
}

export default ajax;


